import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import "../bootstrap.min.css"

import { H1, H2, P } from "../components/text"
import Section from "../components/section"
import Footer from "../components/footer"
import Button from "../components/button"

import imageMagasin from "../images/magasin.jpg"
import imageTraiteur from "../images/traiteur.jpg"
import imageVins from "../images/vins.jpg"
import imagePanier from "../images/panier.jpg"
import imagePanettone from "../images/panettone-low.jpg"
import imageGinNoel from "../images/gin-noel.jpg"

import Header from "../components/header"
import Hero from "../components/hero"

const Page = () => (
  <div>
    <SEO title="Raineri" />
    <Header/>

    {/* <Hero title="Vins & Spécialités italiennes" subtitle="Qualità e tradizione italiana dal 1956" image={imageMagasin}/> */}
    <Hero title="Joyeuses Fêtes à tous !" subtitle="Vins & Spécialités italiennes" image={imageGinNoel} ctaText="Voir menu des fêtes" ctaLink="fetes"/>

    {/* <div className="text-center" style={{backgroundColor: "rgb(60, 52, 47)", marginBottom: "20px", color: "white", padding: "30px", fontSize: "20px"}}>
      <H1>Dégustation Vin Printemps 2024</H1>
      <p>Le 1 et 2 juin au Château de Petit Leez</p>
      <p>De 13 à 18h.</p>
      <br/>
      <br/>
      <Button href="/degustation">Voir les détails</Button>
      <br/>
      <br/>
    </div> */}

    <br/>

    <H1 className="text-center">Épicerie fine</H1>

    {/* Menu fete */}
    <Section className="container">
      <div className="row">
        <div className="col-md-6 mb-5">
          <H2>Menu des Fêtes</H2>
          <P>Le menu des Fêtes de fin d'année est enfin là !</P>
          <P>Réservez votre repas au plus vite !</P>
          <br/>
          <Button href="/fetes">✨ Voir menu des Fêtes 🎄</Button>
        </div>
        <div className="col-md-6 mb-5">
          <img src={imageGinNoel} className="img-fluid"/>
        </div>
      </div>
    </Section>

    {/* Practical info */}
    <Section className="container">
      <div className="row">
        <div className="col-md-6 mb-5">
          <img src={imageMagasin} className="img-fluid"/>
        </div>
        <div className="col-md-6 mb-5">
          <H2>Notre magasin à Gembloux</H2>
          
          <P>Découvrez notre assortiment de produits importés d’Italie.</P>
          <P>
          <a href="https://goo.gl/maps/1Q9oNFVJYNurCvSR6" target="_blank">Rue de la sucrerie 4A, 5030 Gembloux</a> <br/>
            Tel: 081 61 46 10
          </P>
          <P>TVA BE828 987 536</P>
          <P>gembloux@raineri.be</P>
          <P>Ouvert de mardi à jeudi de 9h à 18h,</P>
          <P>le vendredi de 9h à 18h30,</P>
          <P>et samedi de 9h à 18h</P>
          <P style={{fontStyle: 'italic'}}>Fermé le lundi et dimanche</P>
          <br/>
          <Button href="/magasin">En savoir plus</Button>
        </div>
      </div>
    </Section>

    {/* Service traiteur */}
    <Section className="container">
      <H2 className="d-md-none">Service traiteur</H2>
      <div className="row">
      <div className="col-md-6">
          <img src={imageTraiteur} className="img-fluid"/>
        </div>
        <div className="col-md-6">
          <H2 className="d-none d-md-block">Service traiteur</H2>
          <P>Pour la famille, des invités ou entre collègues, pour des petites ou grandes occasions, notre service traiteur est à votre disposition.</P>
          <P>Parmi les plats, nous proposons des lasagnes, cannellonis, ravioles et bien d’autres spécialités. Nous vous invitons à venir les découvrir en magasin.</P>
          <br/>
          <Button href="/traiteur">Voir le menu de la semaine</Button>
        </div>

      </div>
    </Section>

    {/* Vins et spiritueux */}
    <Section className="container">
      <H2 className="d-md-none">Sélection de vins et spiritueux</H2>
      <div className="row">
        <div className="col-md-6">
          <img src={imageVins} className="img-fluid"/>
        </div>
        <div className="col-md-6">
          <H2 className="d-none d-md-block">Sélection de vins et spiritueux</H2>
          <P>Nous proposons une gamme étendue de Spumante, Prosecco, Vins, Grappa et spiritueux de toutes les régions de la botte italienne.</P>
          <P>Depuis 50 ans, Raineri est en étroite collaboration avec plusieurs producteurs : Sartori, Cecchi, Tasca d’almerita, Sella & Mosca...</P>
          <P>Notre gérant est à votre disposition pour vous guider dans votre sélection et vous assurer d’emporter un vin de qualité quelque soit votre budget.</P>
          <br/>
          <Button href="/vins-et-spiritueux">Découvrir la sélection</Button>
        </div>
      </div>
    </Section>

    <H2 style={{textTransform: "uppercase", textAlign: "center", padding: "50px", fontSize: "60px"}}>Pour offrir</H2>

    {/* Paniers composés */}
    <Section className="container">
      <div className="row">
        <div className="col-md-6 mb-5">
          <H2>Paniers composés</H2>
          <P>Besoin d’une idée cadeau pour les fêtes ?</P>
          <P>Venez composer un panier selon vos envies et votre budget.</P>
          <P>Nous vous accompagnons dans votre choix ou nous réalisons des compositions sur mesure.</P>
          <br/>
          <Button href="/magasin">Prendre contact</Button>
        </div>
        <div className="col-md-6 mb-5">
          <img src={imagePanier} className="img-fluid"/>
        </div>
      </div>
    </Section>

    <Footer/>
  </div>
)

export default Page
